import React from "react";
import dimensions from "styles/dimensions";
import styled from "@emotion/styled";

const MapContainer = styled.div`
  position: relative;
  padding: 0;
  height: auto;
  width: 100%;
  
  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    flex: 1 2 50%;
  }
`;

const IFrame = styled.div`
  position: relative;
  width: 100%;
  
  &::before {
    content: "";
    display: block;
    width: 100%;
    padding-bottom: 80%;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  @media (min-width: ${dimensions.tabletPortraitUp}px) {
    &::before {
      padding-bottom: 48%;
    }
  }

  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    &::before {
      padding-bottom: 80%;
    }
  }

  @media (min-width: ${dimensions.desktopUp}px) {
    &::before {
      padding-bottom: 48%;
    }
  }
`;

const createMarkup = (embed) => {
  return {
    __html: embed
  };
}

const Map = (props) => {
  return (
    <>
      <MapContainer>
        <IFrame dangerouslySetInnerHTML={createMarkup(props.embed)} />
      </MapContainer>
    </>
  );
};

export default Map;
