import React, { Component } from "react";
import styled from "@emotion/styled";
import colors from "styles/colors";
import dimensions from "styles/dimensions";
import { gridColumns } from "styles/structure";
import CleanInput from "components/_ui/CleanInput";
import CleanTextArea from "components/_ui/CleanTextArea";
import ArrowButton from "components/_ui/ArrowButton";
import Button from "components/_ui/Button";
import { navigate } from "gatsby";

const ContactFormContainer = styled.div`
  position: relative;
  padding: 3.2rem 2.4rem 5.6rem;
  margin-top: 3.2rem;
  margin-bottom: 8rem;

  h3 {
    font-weight: 400;
    margin-bottom: 4rem;
  }

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    width: 100vw;
    height: 100%;
    transform: translateX(-50%);
    background-color: ${colors.grey100};
    z-index: -1;
  }

  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    padding: 7.2rem 9.3334vw 8.8rem;
    margin-top: 8rem;
    margin-bottom: 12rem;

    &::before {
      width: 100%;
      left: 0;
      transform: translateX(0);
    }
  }

  @media (min-width: ${dimensions.desktopUp}px) {
    padding-left: 11.2rem;
    padding-right: 11.2rem;
  }
`;

const ContactFormTopics = styled.div`
  & + * {
    margin-top: 4.8rem;
  }

  li {
    & + * {
      margin-top: 1.6rem;
    }
  }

  @media (min-width: ${dimensions.tabletPortraitUp}px) {
    ul {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }

    li {
      ${gridColumns('1/2', 1.6)}

      margin-right: 1.6rem;

      & + * {
        margin-top: 0;
      }

      &:nth-of-type(2n) {
        margin-right: 0;
      }

      &:nth-of-type(n+3) {
        margin-top: 1.6rem;
      }
    }
  }

  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    & + * {
      margin-top: 7.2rem;
    }

    li {
      ${gridColumns('1/4', 3.2)}

      margin-right: 3.2rem;

      &:nth-of-type(2n) {
        margin-right: 3.2rem;
      }

      &:nth-of-type(4n) {
        margin-right: 0;
      }

      &:nth-of-type(n+3) {
        margin-top: 0;
      }

      &:nth-of-type(n+5) {
        margin-top: 3.2rem;
      }
    }
  }
`;

const ContactFormButton = styled(Button)`
  display: block;
  width: 100%;
  background-color: ${props => props.active ? colors.red600 : "#fff"};
  border: 1px solid ${props => props.active ? colors.red600 : colors.grey700};
  color: ${props => props.active ? "#fff" : colors.grey900};
  font-size: 1.6rem;
  font-weight: 500;
  padding: 2.4rem 2.4rem 2.2rem;

  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    &:hover {
      border-color: ${colors.red600};
    }
  }
`;

const Form = styled.form`
  display: block;
  width: 100%;
  max-width: 59.2rem;
`;

const FormWrap = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: auto;
  grid-gap: 1.4rem;
  margin-bottom: 4rem;

  > * {
    font-size: 1.8rem;
    padding-bottom: 2rem;
  }

  label {
    display: block;
    font-size: 1.6rem;
    font-weight: 500;
    margin-top: 0.8rem;
  }

  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr 1fr 1fr 1fr 3fr;
    grid-gap: 3rem;
    margin-bottom: 8rem;

    .email,
    .title,
    .phone-number,
    .message {
      grid-column: 1 / span 2;
    }
  }
`;

const ContactInput = styled(CleanInput)`
  display: block;
  width: 100%;
  padding: 1.2rem 0 1rem 0.4rem;
  border-bottom: 0.2rem solid ${colors.grey600};
  font-size: 1.6rem;
  line-height: 1.25;
  color: ${colors.grey900};
`;

const ContactTextArea = styled(CleanTextArea)`
  width: 100%;
  padding: 2.4rem 1.6rem;
  font-family: "Gotham";
  font-size: 1.8rem;
  line-height: 1.44;
  color: ${colors.grey900};
  border: 0.2rem solid ${colors.grey600};
  height: 100%;
`;


const StatusMessage = styled.div`
  font-size: 1.6rem;
  color: ${colors.red600};
`;

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
};

export default class ContactForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      firstName: "",
      lastName: "",
      phoneNumber: "",
      title: "",
      message: "",
      statusMessage: "",
      onYourMind: "",
      email: "",
    };
  }

  handleSubmit = async e => {
    e.preventDefault();

    const form = e.target;

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...this.state,
      }),
    })
      .then(() => navigate("/page/success/"))
      .catch(() =>
        this.setState({
          statusMessage: "Something went wrong, please try again later",
        })
      )
      .catch(error =>
        console.error("There was a problem with the form response", error)
      );
  };

  handleChange = e => this.setState({ [e.target.name]: e.target.value });

  handleClick(event) {
    this.setState({ onYourMind: event.text.button_text });
  }

  render() {
    const {
      firstName,
      lastName,
      phoneNumber,
      title,
      message,
      statusMessage,
      onYourMind,
      email,
    } = this.state;

    const { contact } = this.props;

    return (
      <ContactFormContainer>
        {contact.form_question && <h3>{contact.form_question}</h3>}

        <ContactFormTopics>
          {contact && (
            <ul>
              {contact.buttons.map((text, i) => (
                <li key={i}>
                  <ContactFormButton
                    onClick={() => { this.setState({ onYourMind: text.button_text }) }}
                    active={onYourMind === text.button_text}
                  >
                    {text.button_text}
                  </ContactFormButton>
                </li>
              ))}
            </ul>
          )}
        </ContactFormTopics>

        {contact.form_content && <h3>{contact.form_content}</h3>}

        <Form
          name="contact-marino"
          method="post"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          action="/thank-you"
        >
          <input type="hidden" name="form-name" value="contact-marino" />
          <input type="hidden" name="onYourMind" value={onYourMind} />

          <FormWrap>
            <div className="first-name">
              <ContactInput
                name="firstName"
                id="firstName"
                value={firstName}
                required={true}
                onChange={this.handleChange}
              />

              <label htmlFor="firstName">First Name*</label>
            </div>

            <div className="last-name">
              <ContactInput
                name="lastName"
                id="lastName"
                required={true}
                value={lastName}
                onChange={this.handleChange}
              />

              <label htmlFor="lastName">Last Name*</label>
            </div>

            <div className="email">
              <ContactInput
                name="email"
                id="email"
                type="email"
                required={true}
                value={email}
                onChange={this.handleChange}
              />

              <label htmlFor="emailAddress">Email Address*</label>
            </div>

            <div className="phone-number">
              <ContactInput
                name="phoneNumber"
                id="phoneNumber"
                type="tel"
                required={false}
                value={phoneNumber}
                onChange={this.handleChange}
              />

              <label htmlFor="phoneNumber">Phone Number</label>
            </div>

            <div className="title">
              <ContactInput
                name="title"
                id="title"
                required={false}
                value={title}
                onChange={this.handleChange}
              />

              <label htmlFor="title">Company & Title</label>
            </div>

            <div className="message">
              <ContactTextArea
                name="message"
                id="message"
                required={false}
                value={message}
                onChange={this.handleChange}
                placeholder="Type your thoughts here..."
              />

              <label htmlFor="message">Anything else we should know?</label>
            </div>
          </FormWrap>

          <ArrowButton type="submit" text="Send Message" variant="contact" />
        </Form>

        {statusMessage && <StatusMessage>{statusMessage}</StatusMessage>}
      </ContactFormContainer>
    );
  }
}
