import React from "react";
import RichText from "components/RichText";
import { graphql } from "gatsby";
import styled from "@emotion/styled";
import colors from "styles/colors";
import dimensions from "styles/dimensions";
import { Inner } from "styles/structure";
import Layout from "components/Layout";
import SEO from "components/SEO";
import Hero from "components/Hero";
import ContactForm from "components/ContactForm";
import Map from "components/Map";

const ContactInner = styled(Inner)`
  overflow: hidden;
`;

const Location = styled.div`
  width: 100%;
  margin-bottom: 8rem;

  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    display: flex;  
    align-items: center;
    margin-bottom: 12rem;
  }
`;

const Address = styled.div`
  width: 100%;

  p {
    font-size: 2.4rem;
  }
  
  a {
    color: ${colors.red600};
  }
  
  & + * {
    margin-top: 6.4rem;
  }

  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    flex: 1 1 50%;
    padding-left: 9.3334vw;
    
    p {
      display: block;
      font-size: 3.2rem;
    }
    
    a {
      color: ${colors.grey700};
      transition: color 0.12s ease-in-out;
      
      &:hover {
        color: ${colors.red600};
      }
    }
    
    & + * {
      margin-top: 0;
      margin-left: 6.4rem;
    }
  }
  
  @media (min-width: ${dimensions.desktopUp}px) {
    padding-left: 11.2rem;
  }
`;

const RenderBody = ({ contact }) => (
  <>
    <Hero
      title={contact.introduction_heading}
      variant="condensed"
    />

    <ContactInner>
      <ContactForm contact={contact} />

      <Location>
        <Address>
          <RichText render={contact.contact_info} />
        </Address>

        <Map embed={contact.embed} />
      </Location>
    </ContactInner>
  </>
);

const Contact = ({ data }) => {
  const contact = data.prismic.allContacts.edges[0].node;

  if (!contact) return null;

  return (
    <Layout headerStyle="dark">
      <SEO
        title={contact.social_title || "Contact Us"}
        description={contact.social_description ? contact.social_description : null}
        image={contact.social_image ? contact.social_image.url : null}
      />

      <RenderBody contact={contact} />
    </Layout>
  );
};

export default Contact;

export const query = graphql`
  {
    prismic {
      allContacts {
        edges {
          node {
            introduction_heading
            contact_info
            form_question
            embed
            form_content
            buttons {
              button_text
            }
            social_title
            social_description
            social_image
          }
        }
      }
    }
  }
`;
